// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  room: getIcon('ic_room'),
  location: getIcon('ic_location'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  app: getIcon('ic_app'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.app }],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'renter',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.renter.list },
          { title: 'create', path: PATH_DASHBOARD.renter.new },
        ],
      },
      {
        title: 'location',
        path: PATH_DASHBOARD.location.root,
        icon: ICONS.location,
        children: [
          { title: 'list', path: PATH_DASHBOARD.location.list },
          { title: 'create', path: PATH_DASHBOARD.location.new },
        ],
      },
      {
        title: 'room',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.room,
        children: [
          { title: 'list', path: PATH_DASHBOARD.room.list },
          { title: 'create', path: PATH_DASHBOARD.room.new },
        ],
      },
      {
        title: 'bill',
        path: PATH_DASHBOARD.bill.root,
        icon: ICONS.invoice,
        children: [
          { title: 'list', path: PATH_DASHBOARD.bill.list },
          { title: 'billable', path: PATH_DASHBOARD.bill.billable },
        ],
      },
      {
        title: 'electric plan',
        path: PATH_DASHBOARD.electricPlan.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'list', path: PATH_DASHBOARD.electricPlan.list },
          { title: 'new', path: PATH_DASHBOARD.electricPlan.new },
        ],
      },
      {
        title: 'water plan',
        path: PATH_DASHBOARD.waterPlan.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'list', path: PATH_DASHBOARD.waterPlan.list },
          { title: 'new', path: PATH_DASHBOARD.waterPlan.new },
        ],
      },
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.user.profile },
          { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new },
          { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
          { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },
    ],
  },
];

export default navConfig;
