import PropTypes from 'prop-types';
// form
import { useFormContext, Controller, useWatch } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
// ----------------------------------------------------------------------

RHFNumberHiddenField.propTypes = {
  name: PropTypes.string,
  hidden: PropTypes.object,
  defaultValues: PropTypes.string,
  vndSign: PropTypes.bool,
};

export default function RHFNumberHiddenField({ name, hidden, defaultValues, vndSign, ...other }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [previousValue, setPreviousValue] = useState(undefined);
  const [isHide, setIsHide] = useState(false);
  const { conditionField, showValue } = hidden;
  const watchValue = useWatch({ control, name: conditionField });
  useEffect(() => {
    if (watchValue === showValue) {
      setIsHide(false);
      setValue(`${name}_mask`, previousValue);
      setPreviousValue(undefined);
    } else {
      setIsHide(true);
      setPreviousValue(getValues(name));
      setValue(`${name}_mask`, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValue, showValue]);

  return (
    <Controller
      name={`${name}_mask`}
      control={control}
      defaultValue={defaultValues}
      render={({ field }) => (
        <>
          {isHide ? (
            <></>
          ) : (
            <NumericFormat
              {...field}
              ref={null}
              customInput={TextField}
              fullWidth
              thousandSeparator
              suffix={!vndSign ? '' : 'VND'}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              imeMode="disabled"
              onValueChange={(v) => {
                setValue(name, v.value);
              }}
              {...other}
            />
          )}
        </>
      )}
    />
  );
}
