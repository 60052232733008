import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'location',
          children: [
            { element: <Navigate to="/dashboard/locations/list" replace />, index: true },
            { path: 'list', element: <LocationList /> },
            { path: 'new', element: <LocationCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
          ],
        },
        {
          path: 'waterPlan',
          children: [
            { element: <Navigate to="/dashboard/waterPlan/list" replace />, index: true },
            { path: 'list', element: <WaterPlanList /> },
            { path: 'new', element: <WaterPlanCreate /> },
            { path: ':id/edit', element: <WaterPlanEdit /> },
          ],
        },
        {
          path: 'electricPlan',
          children: [
            { element: <Navigate to="/dashboard/electricPlan/list" replace />, index: true },
            { path: 'list', element: <ElectricPlanList /> },
            { path: 'new', element: <ElectricPlanCreate /> },
            { path: ':id/edit', element: <ElectricPlanEdit /> },
          ],
        },
        {
          path: 'renter',
          children: [
            { element: <Navigate to="/dashboard/renter/list" replace />, index: true },
            { path: 'list', element: <RenterList /> },
            { path: 'new', element: <RenterCreate /> },
            { path: ':id/edit', element: <RenterEdit /> },
            { path: ':id/addCredit', element: <RenterAddCredit /> },
            { path: ':id/bill/:billId/edit', element: <BillEdit /> },
          ],
        },
        {
          path: 'room',
          children: [
            { element: <Navigate to="/dashboard/room/list" replace />, index: true },
            { path: 'list', element: <RoomList /> },
            { path: 'new', element: <RoomCreate /> },
            { path: ':id/editCost', element: <RoomEditCost /> },
            { path: ':id/editPlanAndMeter', element: <RoomEditPlanAndMeter /> },
          ],
        },
        {
          path: 'bill',
          children: [
            { element: <Navigate to="/dashboard/bill/list" replace />, index: true },
            { path: 'list', element: <BillList /> },
            { path: 'billable', element: <RenterBillable /> },
            { path: 'renter/:id/room/:roomId/:dueDate', element: <BillCreate /> },
            { path: ':id/pay', element: <>Pay Bill</> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: <Navigate to="/dashboard" replace />,
          index: true,
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// LOCATION
const LocationCreate = Loadable(lazy(() => import('../pages/dashboard/LocationCreate')));
const LocationList = Loadable(lazy(() => import('../pages/dashboard/LocationList')));

// Water Plan
const WaterPlanCreate = Loadable(lazy(() => import('../pages/dashboard/WaterPlanCreate')));
const WaterPlanList = Loadable(lazy(() => import('../pages/dashboard/WaterPlanList')));
const WaterPlanEdit = Loadable(lazy(() => import('../pages/dashboard/WaterPlanEdit')));

// Electric Plan
const ElectricPlanCreate = Loadable(lazy(() => import('../pages/dashboard/ElectricPlanCreate')));
const ElectricPlanList = Loadable(lazy(() => import('../pages/dashboard/ElectricPlanList')));
const ElectricPlanEdit = Loadable(lazy(() => import('../pages/dashboard/ElectricPlanEdit')));

// Renter
const RenterCreate = Loadable(lazy(() => import('../pages/dashboard/RenterCreate')));
const RenterList = Loadable(lazy(() => import('../pages/dashboard/RenterList')));
const RenterEdit = Loadable(lazy(() => import('../pages/dashboard/RenterEdit')));
const RenterAddCredit = Loadable(lazy(() => import('../pages/dashboard/RenterAddCredit')));

// Room
const RoomCreate = Loadable(lazy(() => import('../pages/dashboard/RoomCreate')));
const RoomList = Loadable(lazy(() => import('../pages/dashboard/RoomList')));
const RoomEditCost = Loadable(lazy(() => import('../pages/dashboard/RoomEditCost')));
const RoomEditPlanAndMeter = Loadable(lazy(() => import('../pages/dashboard/RoomEditPlanAndMeter')));

// Bill
const BillCreate = Loadable(lazy(() => import('../pages/dashboard/BillCreate')));
const RenterBillable = Loadable(lazy(() => import('../pages/dashboard/BillListBillable')));
const BillList = Loadable(lazy(() => import('../pages/dashboard/BillList')));
const BillEdit = Loadable(lazy(() => import('../pages/dashboard/BillEdit')));
