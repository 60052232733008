// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

export default function ThemeChanger() {
  const { themeMode, onToggleMode } = useSettings();

  return (
    <>
      <IconButtonAnimate
        onClick={() => onToggleMode()}
        sx={{
          width: 40,
          height: 40,
          ...{ bgcolor: themeMode === 'light' ? 'common.white' : 'grey.800' },
        }}
      >
        <Iconify icon={themeMode === 'light' ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={28} height={28} />
      </IconButtonAnimate>
    </>
  );
}
