import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller, useWatch } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

import { NumericFormat } from 'react-number-format';
// ----------------------------------------------------------------------

RHFNumberField.propTypes = {
  name: PropTypes.string,
  vndSign: PropTypes.bool,
  value: PropTypes.string,
  defaultValues: PropTypes.string,
};

export default function RHFNumberField({ name, defaultValues, value, vndSign, ...other }) {
  const [localValue, setLocalValue] = useState('');
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const _value = useWatch({ control, name });
  useEffect(() => {
    setLocalValue(_value);
  }, [_value]);

  return (
    <>
      <Controller
        name={`${name}_mask`}
        control={control}
        defaultValue={!value ? defaultValues : value}
        render={({ field }) => (
          <NumericFormat
            {...field}
            ref={null}
            customInput={TextField}
            fullWidth
            thousandSeparator
            suffix={!vndSign ? '' : ' VND'}
            error={!!errors[name]}
            imeMode="disabled"
            helperText={errors[name]?.message}
            onValueChange={(v) => {
              if (v.floatValue) setLocalValue(v.value);
              else setLocalValue(null);
            }}
            onBlur={() => {
              setValue(name, localValue);
            }}
            {...other}
          />
        )}
      />
    </>
  );
}
