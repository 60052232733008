// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  renter: {
    list: path(ROOTS_DASHBOARD, '/renter/list'),
    new: path(ROOTS_DASHBOARD, '/renter/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/renter/${id}/edit`),
    addCredit: (id) => path(ROOTS_DASHBOARD, `/renter/${id}/addCredit`),
    billable: path(ROOTS_DASHBOARD, '/renter/billable'),
    editBill: (id, billId) => path(ROOTS_DASHBOARD, `/renter/${id}/bill/${billId}/edit`),
  },
  room: {
    list: path(ROOTS_DASHBOARD, '/room/list'),
    new: path(ROOTS_DASHBOARD, '/room/new'),
    checkIn: (id) => path(ROOTS_DASHBOARD, `/room/${id}/checkIn`),
    checkout: (id) => path(ROOTS_DASHBOARD, `/room/${id}/checkout`),
    editCost: (id) => path(ROOTS_DASHBOARD, `/room/${id}/editCost`),
    editPlanAndMeters: (id) => path(ROOTS_DASHBOARD, `/room/${id}/editPlanAndMeter`),
  },
  location: {
    list: path(ROOTS_DASHBOARD, '/location/list'),
    new: path(ROOTS_DASHBOARD, '/location/new'),
    edit: path(ROOTS_DASHBOARD, '/location/edit'),
  },
  bill: {
    list: path(ROOTS_DASHBOARD, '/bill/list'),
    billable: path(ROOTS_DASHBOARD, '/bill/billable'),
    new: (renterId, roomId, dueDate) => path(ROOTS_DASHBOARD, `/bill/renter/${renterId}/room/${roomId}/${dueDate}`),
    update: (id) => path(ROOTS_DASHBOARD, `/bill/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/bill/${id}`),
    pay: (id) => path(ROOTS_DASHBOARD, `/bill/${id}/pay`),
  },
  electricPlan: {
    list: path(ROOTS_DASHBOARD, '/electricPlan/list'),
    new: path(ROOTS_DASHBOARD, '/electricPlan/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/electricPlan/${id}/edit`),
  },
  waterPlan: {
    list: path(ROOTS_DASHBOARD, '/waterPlan/list'),
    new: path(ROOTS_DASHBOARD, '/waterPlan/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/waterPlan/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
